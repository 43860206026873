body {
    background-color: rgba(0, 0, 0, 0.805);
    flex-wrap: wrap;
}

.notebook-header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: white;
    padding: 10px;
    position: relative;
    left: 1em;
}

.notebook-main {
    flex-wrap: wrap;
    padding: 15px;
    color: rgb(165, 165, 165);
    flex-shrink: 0;
}

.notebook-main-underline {
    height: 3px;
    background: rgb(78, 144, 172);
    width: 100px;
}

br {
    display: block;
    margin: 5px 0;
 }